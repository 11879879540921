:root {
  --primary: #172018;
  --yellow: #EBB803;
  --salmon: #dd3a58;
}

div {
  box-sizing: border-box;
}

html,
body {
  background: #ed7029;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'forma-djr-micro', sans-serif;
  line-height: 1.5;
}

a {
  font-family: 'forma-djr-micro', sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'forma-djr-micro', sans-serif;
}